import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { PortableText, DefaultHeader, FadeInUp } from "components";
import styled from "styled-components";
import { Facebook, Linkedin, Twitter } from "assets/svgs";
import Img from "gatsby-image";
import { getNewsUrl } from "../../lib/helpers";

function NewsArticle({ post, recentPosts }) {
  const { _rawBody, newsCategories, title, mainImage, publishedAt } = post;
  const url = typeof window !== "undefined" ? window.location.href : "";
  return (
    <>
      {mainImage && mainImage.asset && (
        <DefaultHeader
          sanityImage={mainImage}
          title="LHS News"
          description="Check in with LHS here to get our latest company news, blogs and relevant industry content. "
          link="/news"
          linkText="back to all news"
        />
      )}
      <Container>
        <Grid>
          <Content>
            <FadeInUp>
            <Socials>
              <p>Share on:</p>
              <Social target="blank" href={`https://www.facebook.com/sharer.php?u=${url}`}>
                <Facebook />
              </Social>
              <Social
                target="blank"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
              >
                <Linkedin />
              </Social>
            </Socials>

                        </FadeInUp>
            <Title>{title}</Title>

            {_rawBody && <PortableText blocks={_rawBody} />}
          </Content>
          <Aside className="metaContent">
          <FadeInUp>
                <PostGrid>
                  <RecentPostsTitle>Other News & Projects</RecentPostsTitle>
                  {recentPosts.map((node) => (
                    <RecentPost key={node.id} to={getNewsUrl(node.publishedAt, node.slug.current)}>
                      {node.mainImage && node.mainImage.asset && (
                        <RecentPostImageContainer>
                          <Img fluid={node.mainImage.asset.fluid} alt="banner" />
                        </RecentPostImageContainer>
                      )}
                      {node.title && <RecentPostTitle>{node.title}</RecentPostTitle>}
  
                      {node._rawExcerpt && (
                        <RecentPostExcerpt>
                          <PortableText blocks={node._rawExcerpt} />
                        </RecentPostExcerpt>
                      )}
                    </RecentPost>
                  ))}
                </PostGrid>
                </FadeInUp>
          </Aside>
        </Grid>
      </Container>
    </>
  );
}

const RecentPostExcerpt = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
  color: #232528;
`;

const RecentPostTitle = styled.h3`
  color: #243f93;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 1em;
  margin-bottom: 1.25em;
`;

const RecentPostImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute !important;
  }
`;

const RecentPost = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  padding-bottom: 2rem;
  transition: 0.5s;
  text-decoration: none;
  &:after {
    opacity: 0;
    content: "Show More";
    font-weight: 600;
    color: #f94c00;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1rem;
    transition: 0.5s;
  }

  &:hover {
    background: #eaf6ff;
    &:after {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    &:after {
      bottom: 3rem;
      left: 4rem;
    }
  }
  @media (min-width: 1150px) {
    padding: 1rem;
    padding-bottom: 2rem;
    &:after {
      bottom: 1rem;
      left: 1rem;
    }
  }
`;

const RecentPostsTitle = styled.h3`
  margin-top: 2rem;
  font-weight: 600;
  color: #223f92;
  @media (min-width:768px){
    grid-column: 1 / span 2;
  }
  @media (min-width: 1150px) {
    grid-column: auto;
    margin-left: 1rem;
  }
`;

const PostGrid = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  border-top: 1px solid #223f92;
  @media (min-width:768px){
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    margin-top: 4rem;
    grid-template-columns: 1fr;
  }
`;

const PublishedAt = styled.div`
  margin: 1em 0;
  font-size: 0.75rem;
  color: #223f92;
`;


const Title = styled.h2`
  color: #223f92 !important;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 600 !important;
  margin: 1rem 0;
  @media (min-width: 1150px) {
    font-size: 3rem !important;
    line-height: 1.25em;
    margin-bottom: 0.75em;
  }
`;

const Social = styled.a`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 1px solid #f94c00;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1rem;
    height: auto;
    max-height: 1rem;
    max-width: 1rem;
    path {
      fill: #223f92;
    }
  }
`;

const Socials = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  p {
    font-weight: 300;
    font-size: 1rem;
  }
  @media (min-width: 1150px) {
    margin-bottom: 2rem;
  }
`;

const Aside = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media (min-width: 1150px) {
    grid-column: 8/11;
  }
`;

const Content = styled.div`
  a {
    color: #f94c00;
    &:hover {
      color: #fa4d02;
    }
  }
  h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: 300;
    color: #232528;
  }
  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5em;
  }
  figure {
    margin: 3rem 0;
    padding: 0;
    img {
      max-width: 100%;
    }
  }
  @media (min-width: 1150px) {
    grid-column: 1/7;

    p {
      font-size: 1.125rem;
    }
  }
`;

const Grid = styled.section`
  grid-column: 1/7;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  grid-gap: 0.625rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.25rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem;
  }
`;

export default NewsArticle;
