import * as React from "react";

function SvgLinkedin(props) {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.448 13.24V5.081H1.001v8.16h2.447zm0-11.073c0-.728-.48-1.262-1.295-1.262-.72 0-1.248.534-1.248 1.262 0 .729.48 1.263 1.248 1.263.816.049 1.295-.534 1.295-1.263zm4.08 11.073V8.578c0-.243 0-.486.096-.68.192-.486.624-1.02 1.392-1.02 1.008 0 1.584.583 1.584 1.7v4.662h2.495V8.48c0-2.573-1.344-3.739-3.12-3.739-1.487 0-2.111.826-2.495 1.409V5.08H5.032c.048.777 0 8.208 0 8.208h2.496v-.049z"
        fill="#223F92"
      />
    </svg>
  );
}

export default SvgLinkedin;
