import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useWindowSize } from "hooks";
import Img from "gatsby-image";
import { Arrow } from "assets/svgs";
import { FadeIn } from 'components'

export default ({ image, sanityImage, imageFluid, title, description, link, linkText }) => {
  const { windowHeight } = useWindowSize();
  return (
    <FadeIn>
    <Header windowHeight={windowHeight}>
      <TextContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {link && (
          <Button to={link}>
            <BackArrow /> {linkText}
          </Button>
        )}
      </TextContent>
      <HeaderImage sanityImage={sanityImage ? true : false}>
        {imageFluid && <Img fluid={imageFluid.childImageSharp.fluid} alt="banner" />}
        {sanityImage && <Img fluid={{...sanityImage.asset.fluid, aspectRatio: 1 / 1}} alt="banner" />}
        {image && <img src={image} alt="banner" />}
      </HeaderImage>
    </Header>
    </FadeIn>
  );
};

const BackArrow = styled(Arrow)`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 1em;
  path {
    stroke: white;
    stroke-width: 3px;
    transform: rotate(90deg);
    transform-origin: center;
  }
`;

const Button = styled(Link)`
  background: var(--light-blue);
  height: 3rem;
  width: 12.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  border-radius: 0.425rem;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  margin-bottom: 2rem;
`;

const Description = styled.h4`
  font-size: 1rem;
  line-height: 1.4em;
  font-weight: 600;
  color: white;
  @media (min-width: 1150px) {
    font-size: 1.25rem;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  line-height: 1.25em;
  font-weight: 300;
  color: white;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: textcontent;
  @media (min-width: 768px) {
    padding: 3rem 1rem;
  }
  @media (min-width: 1150px) {
    padding: 2rem 4rem;
    height: 100%;
  }
`;

const HeaderImage = styled.div`
  width: 100%;
  max-height: 100%;
  margin-right: -1rem;
  margin-top: -5rem;
  position: relative;
  grid-area: image;
  justify-self: flex-end;
  overflow: visible;
  .gatsby-image-wrapper {
    width: ${({ sanityImage }) => (sanityImage ? "120%" : "100%")};
    img {
      border-radius: ${({ sanityImage }) => (sanityImage ? "50%" : "0%")};
      top:${({ sanityImage }) => (sanityImage ? "-5rem !important" : "initial")}; ;
    }
  }
  @media (min-width: 1150px) {
    height: 550px;
    width: 550px;
  }
`;

const Header = styled.header`
  position: relative;
  height: 100%;
  min-height: ${({ windowHeight }) => `${windowHeight}px`};
  max-width: 100%;
  background: #223f92;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  grid-template-areas: "image" "textcontent";
  overflow-x: hidden;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textcontent image";
    min-height: auto;
    margin-top: 3.5rem;
    overflow: visible;
  }
  @media (min-width: 1150px) {
    min-height: 30rem;
    height: 30rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textcontent image";
    overflow: visible;
    margin-top: 5rem;
  }
`;
