import * as React from "react";

function SvgQuotationMark(props) {
  return (
    <svg viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.545 16.488H28.21c0-4.704 1.44-8.064 6.336-9.696V.456c-7.968 1.056-13.92 7.584-13.632 16.128v14.688h13.632V16.488zm-20.448 0H7.761c0-4.704 1.44-8.064 6.336-9.696V.456C6.13 1.512.177 8.04.465 16.584v14.688h13.632V16.488z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgQuotationMark;
