import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Arrow } from "assets/svgs";
import { graphql, useStaticQuery } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../../../lib/helpers";
import { useWindowSize } from "hooks";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default () => {
  const [expanded, setExpanded] = useState(0);
  const { windowWidth } = useWindowSize();
  const dropdownToggle = () => {
    setExpanded(expanded === 0 ? 1 : 0);
  };
  const data = useStaticQuery(graphql`
    query DropdownQuery {
      services: allSanityService(limit: 10, sort: { order: ASC, fields: publishedAt }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            serviceSvg
          }
        }
      }
    }
  `);

  const services = (data || {}).services
    ? mapEdgesToNodes(data.services)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
  return (
    <Dropdown expanded={expanded === 1 ? true : false}>
      {windowWidth < 1150 && (
        <Heading onClick={dropdownToggle} expanded={expanded === 1 ? true : false}>
          Services
          <DownArrow expanded={expanded === 0 ? 1 : 0} />
        </Heading>
      )}
      {windowWidth >= 1150 && (
        <Heading onClick={dropdownToggle} expanded={expanded === 1 ? true : false}>
          Services
          <DownArrow expanded={expanded === 0 ? 1 : 0} />
        </Heading>
      )}
      <ContentContainer expanded={expanded === 1 ? true : false}>
        <AnimatePresence initial={false}>
          {expanded &&
            services.map((node) => (
              <Content
                onClick={dropdownToggle}
                key={node.id}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <AnchorLink to={`/services#${node.slug.current}`} title={node.title} stripHash>
                  <SvgContainer dangerouslySetInnerHTML={{ __html: node.serviceSvg }} />
                  {node.title}
                </AnchorLink>
              </Content>
            ))}
        </AnimatePresence>
      </ContentContainer>
    </Dropdown>
  );
};

const SvgContainer = styled.div`
  svg {
    height: 2rem;
    width: 2rem;
  }
`;

const DownArrow = styled(Arrow)`
  margin-left: 0.5rem;
  margin-right: 0;
  width: 1.25rem;
  height: 1rem;
  min-width: 1rem;
  height: auto;
  transition-duration: 0.25s;
  transform: ${({ expanded }) => (expanded ? "none" : "scale(-1)")};
  path {
    transition: 0.25s;
    stroke: ${({ expanded }) => (expanded ? "var(--secondary-color)" : "var(--black)")};
  }
  @media (min-width: 1150px) {
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
    path {
      stroke: ${({ expanded }) => (expanded ? "var(--black)" : "var(--secondary-color)")};
    }
  }
`;

const Content = styled(motion.section)`
  margin: 2rem auto;
  a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center;
    grid-column-gap: 1rem;
    width: 100%;
    max-width: 250px;
    margin: 2rem auto;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5em;
    text-decoration: none;
    color: #223f92;
    svg {
      height: auto;
      width: auto;
      max-height: 2rem;
      max-width: 2rem;
    }
  }
  @media (min-width: 1150px) {
    margin: 0;
    a {
      font-size: 0.875rem;
      margin: 1rem 0;
      transition: 0.25s;
      &:hover {
        color: var(--secondary-color);
        text-decoration: underline;
        text-decoration-color: #223f92;
      }
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  @media (min-width: 1150px) {
    position: absolute;
    top: 100%;
    left: 0;
    height: auto;
    width: auto;
    background: white;
    padding: 2.5rem;
    display: ${({ expanded }) => (expanded ? "grid" : "none")};
    grid-template-columns: 220px 220px;
    grid-column-gap: 4rem;
    border-radius: 0.5rem;
  }
`;

const Heading = styled(motion.div)`
  width: 100%;
  padding-top: 0.125rem;
  font-family: "Helvetica", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5em;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  color: #232528;
  cursor: pointer;
  @media (min-width: 1150px) {
    width: auto;
    background: transparent;
    color: ${({ expanded }) => (expanded ? "var(--secondary-color)" : "var(--black)")};
    font-size: 1rem;
    line-height: 1em;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.25s;
    padding-top: 3px;
    border-bottom: ${({ expanded }) =>
      expanded ? "3px solid var(--secondary-color)" : "3px solid transparent"};
    &:hover {
      color: var(--secondary-color);
      border-bottom: 3px solid var(--secondary-color);
    }
    &:hover ${DownArrow} {
      path {
        stroke: var(--secondary-color);
      }
    }
  }
`;

const Dropdown = styled.div`
  width: auto;
  height: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  @media (min-width: 1150px) {
    height: 100%;
    position: relative;
    overflow: visible;
  }
`;
