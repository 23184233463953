import React, { useState } from "react";
import { Navbar, IENotSupportedSplash } from "components";
import styled, { createGlobalStyle } from "styled-components";
import Helvetica from "../../assets/fonts/helvetica.otf";
import HelveticaLight from "../../assets/fonts/helvetica-light.otf";
import HelveticaBold from "../../assets/fonts/helvetica-bold.otf";
import { isIE } from "react-device-detect";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => {
  const [navOpen, setNavOpen] = useState(false);
  return !isIE ? (
    <Container>
      <GlobalStyles />
      <Navbar navOpen={navOpen} setNavOpen={setNavOpen} />
      {!navOpen && (
        <>
          <Content>{children}</Content>
        </>
      )}
    </Container>
  ) : (
    <IENotSupportedSplash />
  );
};

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Helvetica;
    src:  url(${Helvetica}) format('otf');
    font-weight:400;
  }
  @font-face {
    font-family: Helvetica;
    src:  url(${HelveticaLight}) format('otf');
    font-weight: 300;
  }
  @font-face {
    font-family: Helvetica;
    src:  url(${HelveticaBold}) format('otf');
    font-weight: 600;
  }
  :root {
    --primary-color: #36A9E1;
    --secondary-color: #F94C00;
    --light-blue: #35A8E0;
    --dark-blue: #223F92;
    --black: #232528;
    --media-min-small: 450px;
    --media-max-small: 449px;
    --media-min-medium: 675px;
    --media-min-large: 900px;
  }

  html {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    font-weight:400;
    overflow-x:hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: var(--color-white);
    color: var(--color-black);
    margin: 0;
  }
  *{
    box-sizing:border-box;
    font-family: 'Helvetica', sans-serif;
  }

  html,
  body,
  body > div,
  body > div > div {
    height: 100%;
  }
`;

const Content = styled.div`
  background: var(--color-white);
`;

const SiteInfo = styled.div`
  text-align: center;
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  @media (min-width: 1440px) {
    box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  }
`;

export default Layout;
