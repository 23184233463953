import React from 'react'
import styled from 'styled-components'

export default ({children}) => {
  return <Container>{children}</Container>
}


const Container = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  padding: 1.5em;
  margin: 0 auto;
  @media (min-width:var(--media-min-small)) {
    padding: 2em;
  }
`
