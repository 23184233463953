import * as React from "react";

function SvgDownload(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489 489" {...props}>
      <path d="M329.2 327.2c-4.5 0-8.1 3.4-8.6 7.9-3.9 38.6-36.5 68.7-76.2 68.7-39.6 0-72.2-30.1-76.2-68.7-.5-4.4-4.1-7.9-8.6-7.9h-104c-21.8 0-39.5 17.7-39.5 39.5v82.8c0 21.8 17.7 39.5 39.5 39.5h377.8c21.8 0 39.5-17.7 39.5-39.5v-82.7c0-21.8-17.7-39.5-39.5-39.5H329.2v-.1z" />
      <path d="M303.5 198.6l-30.9 30.9V28.1C272.6 12.6 260 0 244.5 0c-15.5 0-28.1 12.6-28.1 28.1v201.4l-30.9-30.9c-9.5-9.5-24.7-11.9-35.9-4.4-15.3 10.2-16.8 31.1-4.5 43.4l82.8 82.8c9.2 9.2 24.1 9.2 33.3 0l82.8-82.8c12.3-12.3 10.8-33.2-4.5-43.4-11.3-7.6-26.5-5.2-36 4.4z" />
    </svg>
  );
}

export default SvgDownload;
