import React from 'react'
import { Figure, ImageGallery } from 'components'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: ({node}) => <Figure node={node} />,
    imageGallery: ({node}) => <ImageGallery node={node} />
  }
}

export default serializers;