import * as React from "react";

function SvgChecklistIcon(props) {
  return (
    <svg viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 51.625V4.375C1 2.519 2.487 1 4.304 1h31.392C37.513 1 39 2.519 39 4.375v47.25C39 53.481 37.513 55 35.696 55H4.304C2.487 55 1 53.481 1 51.625zM1 11h38"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M29 1v5H11V1"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 26.6L29.167 23 17.333 33.8 12 29.055l-4 3.6L17.333 41 33 26.6z"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgChecklistIcon;
