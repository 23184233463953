import * as React from "react";

function SvgReportIcon(props) {
  return (
    <svg viewBox="0 0 44 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 4.214V2.071C6 1.43 6.435 1 7.088 1h34.824C42.565 1 43 1.429 43 2.071V44.93C43 45.57 42.565 46 41.912 46h-1.96"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M1 8.046C1 7.418 1.424 7 2.059 7H35.94C36.577 7 37 7.418 37 8.046v45.908c0 .628-.423 1.046-1.059 1.046H2.06C1.424 55 1 54.582 1 53.954V8.046zM1 16h31M4.2 11.45h2.1M8.4 11.45h2.1M12.6 11.45h2.1"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M32 33.082l-1.691-4.14-3.17 1.38c-.635-.955-1.48-1.697-2.326-2.334l1.374-3.184L21.959 23l-1.374 3.184a8.508 8.508 0 00-3.276 0L15.935 23l-4.122 1.698 1.374 3.184c-.951.636-1.691 1.485-2.325 2.334l-3.17-1.38L6 32.977l3.17 1.38a8.613 8.613 0 000 3.289L6 39.025l1.691 4.138 3.17-1.38c.635.956 1.48 1.699 2.326 2.335l-1.374 3.184L15.935 49l1.374-3.184a8.508 8.508 0 003.276 0L21.96 49l4.122-1.698-1.374-3.184c.951-.636 1.691-1.485 2.325-2.334l3.171 1.38 1.691-4.14-3.17-1.38a8.612 8.612 0 000-3.289L32 33.082z"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M23.6 34.063c1.088 2.542-.122 5.447-2.664 6.536-2.541 1.09-5.446-.12-6.535-2.662-1.09-2.542.12-5.447 2.663-6.536 2.541-1.09 5.446.12 6.535 2.662z"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgReportIcon;
