import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../lib/helpers";
import { useWindowSize } from "hooks";
import { wrap } from "@popmotion/popcorn";
import { QuotationMark, Arrow } from "assets/svgs";
import { PortableText, FadeInUp } from "components";

export default () => {
  const { windowWidth } = useWindowSize();

  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      testimonials: allSanityTestimonial {
        edges {
          node {
            Author
            _rawQuote
          }
        }
      }
    }
  `);

  const testimonials = (data || {}).testimonials ? mapEdgesToNodes(data.testimonials) : [];

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const index = wrap(0, testimonials.length, page);
  const rightIndex = wrap(0, testimonials.length, page + 1);
  return (
    <Container>
      <FadeInUp>
      {windowWidth >= 1150 && (
        <Left
          onClick={() => {
            paginate(-2);
          }}
        />
      )}
      {windowWidth < 1150 && (
        <Left
          onClick={() => {
            paginate(-1);
          }}
        />
      )}
      <Testimonials
        key={page}
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        custom={direction}
        transition={{
          x: { type: "spring", stiffness: 300, damping: 200 },
          opacity: { duration: 0.75 },
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold && windowWidth >= 1150) {
            paginate(2);
          } else if (swipe > swipeConfidenceThreshold && windowWidth >= 1150) {
            paginate(-2);
          } else if (swipe < -swipeConfidenceThreshold && windowWidth < 1150) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold && windowWidth < 1150) {
            paginate(-1);
          }
        }}
      >
        <AnimatePresence initial={false} custom={direction}>
          {testimonials.length > 0 && (
            <>
              <Testimonial>
                <QuotationMark />
                <PortableText blocks={testimonials[index]._rawQuote} />
                <Company>{testimonials[index].Author}</Company>
              </Testimonial>
              {windowWidth >= 1150 && (
                <Testimonial>
                  <QuotationMark />
                  <PortableText blocks={testimonials[rightIndex]._rawQuote} />
                  <Company>{testimonials[rightIndex].Author}</Company>
                </Testimonial>
              )}
            </>
          )}
        </AnimatePresence>
      </Testimonials>
      {windowWidth >= 1150 && (
        <Right
          onClick={() => {
            paginate(+2);
          }}
        />
      )}
      {windowWidth < 1150 && (
        <Right
          onClick={() => {
            paginate(+1);
          }}
        />
      )}
      </FadeInUp>
    </Container>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
const Left = styled(Arrow)`
  cursor: pointer;
  padding: 0.75rem;
  min-width: 1rem;
  height: 2rem;
  transform: translateY(-50%) rotate(90deg);
  position: absolute;
  left: -1rem;
  top: 50%;
  z-index:2;
  svg {
    fill: #243f93;
  }
  @media (min-width: 768px) {
    left: 2rem;
  }
  @media (min-width: 1150px) {
    left: -4rem;
  }
`;

const Right = styled(Arrow)`
  cursor: pointer;
  padding: 0.75rem;
  min-width: 1rem;
  height: 2rem;
  transform: translateY(-50%) rotate(-90deg);
  position: absolute;
  right: -1rem;
  top: 50%;
  @media (min-width: 768px) {
    right: 2rem;
  }
  @media (min-width: 1150px) {
    right: -4rem;
  }
`;

const Company = styled.h4`
  color: #243f93;
  align-self: flex-end;
`;

const Testimonial = styled(motion.div)`
  width: 100%;
  background: #eaf6ff;
  border-radius: 6px;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  h4 {
    margin: 0;
    text-align: right;
  }
  svg {
    width: 1.5rem;
    height: auto;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  @media (min-width: 1150px) {
    padding: 3rem 4rem;
    padding-top: 4rem;
    p {
      font-size: 1rem;
      font-weight: 400;
    }
    svg {
      position: absolute;
      top: 2rem;
      left: 2rem;
      width: 2rem;
    }
  }
`;

const Testimonials = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin: 2rem 0;
  padding:1rem;
  @media (min-width: 768px) {
    margin-bottom: 3rem;
    justify-content: center;
    grid-template-columns: 500px;
  }
  @media (min-width:1150px){
    grid-template-columns: 1fr 1fr;
    padding:0;
  }
`;

const Container = styled.section`
  grid-column: 1/7;
  width: 1440px;
  max-width: 100%;
  position:relative;
  @media (min-width: 1150px) {
    grid-column: 2/12;
    padding: 1rem;
  }
`;
