import React from "react";
import styled from "styled-components";
import { EmailIcon, PhoneIcon } from "assets/svgs";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default ({ navOpen }) => {
  return (
    <CallUs navOpen={navOpen}>
      <div>
        <SvgContainer>
          <PhoneIcon />
        </SvgContainer>{" "}
        <a href="tel:+35345868342">+35345868342</a>
      </div>
      <div>
        <SvgContainer>
          <EmailIcon />
        </SvgContainer>{" "}
        <AnchorLink to="/about#contactform" stripHash>Email Us</AnchorLink>
      </div>
    </CallUs>
  );
};

const SvgContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background: #f94c00;
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CallUs = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #223f92;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  transition: 0.5s;
  > div {
    display: flex;
    align-items: center;
    color: white;
    &:first-child {
      margin-right: 1rem;
    }
    svg {
      height: 0.75rem;
      width: 0.75rem;
      max-height: 0.75rem;
    }
    a {
      font-size: 1rem;
      color: white;
      margin: 0;
      text-decoration: none;
      height: auto;
      white-space:nowrap;
    }
  }
  @media (max-width: 1150px) {
    top: 5rem;
    display: ${({ navOpen }) => (navOpen ? "flex" : "none")};
    opacity: ${({ navOpen }) => (navOpen ? 1 : 0)};
    > div {
      a {
        font-size: 0.75rem;
      }
    }
  }
`;
