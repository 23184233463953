import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { getNewsUrl } from "../../lib/helpers";
import { PortableText, FadeInUp } from "components";
import styled from "styled-components";

export default ({ node }) => {
  return (
    <FadeInUp>
      <Post key={node.id} to={getNewsUrl(node.publishedAt, node.slug.current)}>
        {node.mainImage && node.mainImage.asset && (
          <ImageContainer>
            <Img fluid={node.mainImage.asset.fluid} alt="banner" />
          </ImageContainer>
        )}
        {node.title && <Title>{node.title}</Title>}
        {node._rawExcerpt && (
          <Excerpt>
            <PortableText blocks={node._rawExcerpt} />
          </Excerpt>
        )}
      </Post>
    </FadeInUp>
  );
};


const Excerpt = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
  color: #232528;
`;

const Title = styled.h3`
  color: #243f93;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 1em;
  margin-bottom: 1.25em;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute !important;
  }
`;

const Post = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  padding-bottom: 3rem;
  transition: 0.5s;
  text-decoration: none;
  &:after {
    opacity: 0;
    content: "Show More";
    font-weight: 600;
    color: #f94c00;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1rem;
    transition: 0.5s;
  }

  &:hover {
    background: #eaf6ff;
    &:after {
      opacity: 1;
    }
  }
`;
