import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useWindowSize } from "hooks";
import {
  FooterCircle,
  Logo,
  Facebook,
  Linkedin,
  Twitter,
  MobIcon,
  EmailIcon,
  LocationIcon,
  PhoneIcon
} from "assets/svgs";
import FooterBgMobile from "../../assets/images/footer-bg-mobile.jpg";
import FooterLogo from "../../assets/images/footer-logo.png";
import FooterBanner from "../../assets/images/footer-banner.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../../lib/helpers";
import { FadeInUp } from "components";

export default () => {
  const { windowWidth } = useWindowSize();
  const data = useStaticQuery(graphql`
    query FooterServicesQuery {
      services: allSanityService(limit: 10, sort: { order: ASC, fields: publishedAt }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
          }
        }
      }
      brochure :  sanityDownload(title: {eq: "LHS Services C.L.A.S.S."}) {
        title
        pdf {
          asset {
            url
          }
        }
      }
    }
  `);
  const { brochure } = data;
  const services = (data || {}).services
    ? mapEdgesToNodes(data.services).filter(filterOutDocsWithoutSlugs)
    : [];
  return (
    <>
      <FadeInUp>
        <BannerContainer>
          <Banner>
            <img src={FooterBanner} alt="footer banner" />
            <p>
              Want to see what C.L.A.S.S. can do for you? Download our brochure here to find out more.
            </p>
            <Button href={brochure.pdf.asset.url} target="blank">
              Download Brochure
            </Button>
          </Banner>
        </BannerContainer>
      </FadeInUp>
      <FadeInUp>
      <Footer>
        <Container>
          <Sitemap>
            <h3>Sitemap</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/class">C.L.A.S.S.</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/downloads">Downloads</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
          </Sitemap>
          <Services>
            <h3>Services</h3>
            <ul>
              {services.map((node) => (
                <li key={node.id}>
                  <AnchorLink to={`/services#${node.slug.current}`} title={node.title} stripHash>
                    {" "}
                    {node.title}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </Services>
          <YourAccount>
            <h3>Your Account</h3>
            <ul>
              <li>
                <a href="https://classonline.lhsservices.ie/login" target="blank">
                  Sign In
                </a>
              </li>
            </ul>
          </YourAccount>
          <Contact>
            <FooterCircle className="contact-bg" />
            <img src={FooterLogo} alt="logo" />
            <h3>Contact</h3>
            <h3>LHS Services</h3>
            <ul>
              <li>
                <AnchorLink to="/about#contactform" stripHash>
                  <Icon>
                    <EmailIcon />
                  </Icon>{" "}
                  Email Us
                </AnchorLink>
              </li>
              <li>
                <a href="tel: +353 45 868 342">
                  <Icon>
                    <PhoneIcon />
                  </Icon>
                  +353 45 868 342
                </a>
              </li>
              <li>
                <a href="tel: +353 86 257 2888">
                  <Icon>
                    <MobIcon />
                  </Icon>
                  + 353 86 257 2888
                </a>
              </li>
              <li>
                <a href="https://g.page/lifting-and-handling-services?share" target="blank">
                  <Icon>
                    <LocationIcon />
                  </Icon>
                  Unit 3F, Clane Business Park, College Rd,
                  <br /> Common, Clane, Co. Kildare, W91 X5KR
                </a>
              </li>
            </ul>
            <Socials>
              <Social target="blank" href="https://www.facebook.com/liftingandhandlingservices/">
                <Facebook />
              </Social>
              <Social
                target="blank"
                href="https://www.linkedin.com/company/lifting-and-handling-services-ltd/about/"
              >
                <Linkedin />
              </Social>
            </Socials>
            <Terms>
              <Link to="/privacy">Privacy Policy</Link>
            </Terms>
          </Contact>
          <Copyright>
            <p>
              Copyright © 2021 - LHS Services - Designed and Developed by&nbsp;
              <a href="https://agencyx.ie" target="blank">
                 Agency X
              </a>
            </p>
          </Copyright>
        </Container>
      </Footer>
      </FadeInUp>
    </>
  );
};

const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: 0.5s;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    max-height: 1.25rem;
    max-width: 1.25rem;
    path {
      fill: #223f92;
    }
  }
  &:hover {
    background: #f94c00;
    svg {
      path {
        fill: white;
      }
    }
  }
`;

const Socials = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 2rem;
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  display: none;
  @media (min-width: 1150px) {
    display: flex;
  }
`;

const Terms = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 2rem;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  a {
    margin: 0.25rem 1rem 0 0;
    text-decoration: none;
    color: white;
    font-weight: 300;
    font-size: 0.75rem;
    cursor: pointer;
  }
`;

const Button = styled.a`
  background: #35a8e0;
  color: white;
  width: 10rem;
  height: 3.5rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  margin-left: auto;
  text-align:center;
  line-height:1.25em;
  @media (min-width: 1150px) {
    margin-left: 2rem;
    margin-top: 0;
    width: 20rem;
    align-self: center;
  }
`;

const Banner = styled.div`
  background: #f94c00;
  position: relative;
  z-index:2;
  grid-column: 1/7;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  img {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100px;
    height: auto;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: bold;
    color: white;
    text-align: right;
    margin: 0 0 0rem 1.5rem;
    @media (min-width: 1150px) {
      text-align: left;
      margin-left: 7.5rem;
      margin-right: auto;
    }
  }
  @media (min-width: 768px) {
    grid-column: 1/5;
  }
  @media (min-width: 1150px) {
    grid-column: 2/10;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
  }
`;

const BannerContainer = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 3rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  @media (min-width: 1150px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Copyright = styled.div`
  grid-column: 1/7;
  width: 100vw;
  padding: 1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  background: #124fdd;
  position: relative;
  z-index: 2;
  p,
  a {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.5em;
    color: white;
    text-decoration: none;
    width: 100%;
    max-width: 100%;
  }
  @media (min-width: 1150px) {
    background: transparent;
    grid-column: 2/7;
    width: 100%;
    margin-top: -3.75rem;
    margin-left: -1rem;
    p,
    a {
      color: #223f92;
    }
  }
`;

const Icon = styled.span`
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f94c00;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;

  svg {
    width: 1rem;
    max-width: 1rem;
    max-height: 1.25rem;
    height: auto;
  }
`;

const Contact = styled.div`
  grid-column: 1/7;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem;
  position: relative;
  overflow: visible;
  padding-top: 3rem;
  margin-top: 2rem;
  margin-bottom: -1rem;
  .contact-bg {
    height: 200%;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: 768px) {
      left: 17.5rem;
      top: -5rem;
    }
    @media (min-width: 1150px) {
      height: auto;
      width: 60rem;
      transform: none;
      top: -6rem;
      left: -12rem;
    }
  }
  img {
    max-width: 7.5rem;
    height: auto;
    position: absolute;
    z-index: 2;
    top: -3rem;
    right: 2rem;
  }
  h3 {
    font-size: 1rem;
    line-height: 1.75em;
    font-weight: 600;
    color: white;
    position: relative;
    z-index: 2;
  }
  ul {
    padding: 0;
    margin-top: 2rem;
    list-style: none;
    position: relative;
    z-index: 2;
    li {
      margin-bottom: 1rem;
      cursor: pointer;

      a {
        display: flex;
        align-items: center;
        color: white;
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.5em;
        text-decoration: none;
      }
    }
  }
  @media (min-width: 768px) {
    grid-row: 1/2;
    grid-column: 5/7;
    margin-top: -3rem;
    margin-left: -3rem;
    padding-left: 1rem;
    img {
      top: -9rem;
      max-width: 10rem;
    }
  }
  @media (min-width: 1150px) {
    grid-column: 9/12;
    padding: 0;
    margin-top: 0;
    padding-left: 3rem;
    img {
      max-width: 12rem;
      height: auto;
      position: absolute;
      z-index: 2;
      top: -12.5rem;
      right: -3rem;
    }
  }
`;

const Column = styled.div`
  position: relative;
  z-index: 2;
  h3 {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
    color: #223f92;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 0.5rem;
      a {
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 1em;
        color: #223f92;
        text-decoration: none;
        width: 100%;
        transition: 0.25s;
        &:hover {
          color: #f94c00;
        }
      }
    }
  }
`;

const Sitemap = styled(Column)`
  grid-column: 1/3;
  @media (min-width: 768px) {
    grid-column: 1/2;
  }
  @media (min-width: 1150px) {
    grid-column: 2/3;
  }
`;

const Services = styled(Column)`
  grid-column: 3/7;
  @media (min-width: 768px) {
    grid-column: 2/4;
  }
  @media (min-width: 1150px) {
    grid-column: 4/7;
    margin-left: -3rem;
  }
`;

const YourAccount = styled(Column)`
  grid-column: 1/4;
  @media (min-width: 768px) {
    grid-column: 1/3;
  }
  @media (min-width: 1150px) {
    grid-column: 7/9;
    margin-left: -1.5rem;
  }
`;

const Container = styled.div`
  width: 1440px;
  max-width: 100%;
  margin: 2rem auto;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  grid-gap: 0.625rem;
  @media (min-width: 768px) {
    grid-column-gap: 2rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2rem;
  }
`;

const Footer = styled.footer`
  width: 100%;
  position: relative;
  height: auto;
  background: url(${FooterBgMobile});
  background-size: cover;
  background-position: top left;
  @media (min-width: 768px) {
    margin-top: 3rem;
  }
  @media (min-width: 1150px) {
    margin-top: 4rem;
  }
`;
