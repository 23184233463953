import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";
import { FadeInUp } from 'components'

export default ({ node }) => {
  if (!node || !node.images) {
    return null;
  }
  return (
    <FadeInUp>
    <ImageGallery>
      {node.images.map((image) => {
        const fluidProps = getFluidGatsbyImage(
          image.asset._id,
          { maxWidth: 675 },
          clientConfig.sanity
        );
        return <Img key={image._key} fluid={fluidProps} alt="Blog Post Image" />;
      })}
    </ImageGallery>
    </FadeInUp>
  );
};

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 2rem 0;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
