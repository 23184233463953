import * as React from "react";

function SvgPhoneIcon(props) {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.168 8.738L9.579 7.15a.764.764 0 00-1.065 0l-.927.926L3.928 4.41l.927-.925a.762.762 0 000-1.064L3.265.831a.764.764 0 00-1.065 0L1.123 1.907a1.766 1.766 0 000 2.477l6.488 6.494a1.77 1.77 0 002.48 0l1.078-1.076a.762.762 0 000-1.064z"
        stroke="#fff"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgPhoneIcon;
