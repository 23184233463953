import * as React from "react";

function SvgBannerCircle(props) {
  return (
    <svg viewBox="0 0 1255 1255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={627.5} cy={627.5} r={627.5} fill="#124FDD" />
      <path
        d="M628 1204c41.46 0 79.959-4.44 118.458-11.85v-1.48l11.846-230.989c-41.46 16.288-85.882 25.172-130.304 25.172-57.748 0-112.534-13.326-162.879-39.979-41.46-20.73-78.478-50.344-108.092-85.882l143.629-82.92-433.85-281.337C56.442 537.676 52 582.098 52 628c0 318.355 257.646 576 576 576z"
        fill="#fff"
        fillOpacity={0.3}
      />
      <path
        d="M848.627 349.625C933.028 417.738 983.373 518.427 983.373 628c0 41.46-7.404 81.44-20.73 119.938l-143.63-84.401-25.172 516.773C1030.76 1109.23 1204 888.607 1204 628c0-148.072-56.27-282.817-148.07-384.987h-1.48L848.627 349.625z"
        fill="#35A8E0"
        fillOpacity={0.3}
      />
      <path
        d="M275.588 576.174c10.365-71.074 42.941-139.187 93.286-192.493 51.825-54.787 119.938-91.805 193.974-106.612V444.39l460.502-235.434C919.701 111.229 780.514 52 628 52 371.835 52 155.65 219.32 80.133 448.833l1.481 1.48 193.974 125.861z"
        fill="#243F93"
        fillOpacity={0.3}
      />
    </svg>
  );
}

export default SvgBannerCircle;
