import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { BannerCircle } from "assets/svgs";
import LHSVideoBanner from "../../../assets/images/lhs-video-banner.jpg";
import LHSVideoMP4 from "../../../assets/videos/lhs-video.mp4";
import LHSVideoWebm from "../../../assets/videos/lhs-video.webm";
import { FadeIn } from "components";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      bg1: file(relativePath: { eq: "assets/images/bg-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bg2: file(relativePath: { eq: "assets/images/bg-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bg3: file(relativePath: { eq: "assets/images/bg-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bg4: file(relativePath: { eq: "assets/images/bg-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bg5: file(relativePath: { eq: "assets/images/bg-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const { bg1, bg2, bg3, bg4, bg5 } = data;

  const getBackground = (slide, data) => {
    switch (slide) {
      case 1:
        return <HeaderImage fluid={bg1.childImageSharp.fluid} alt="Statutory Inspections" />;
        break;
      case 2:
        return <HeaderImage fluid={bg2.childImageSharp.fluid} alt="C.L.A.S.S"/>;
        break;
      case 3:
        return <HeaderImage fluid={bg3.childImageSharp.fluid} alt="Trust your partners in safety" />;
        break;
      case 4:
        return <HeaderImage fluid={bg4.childImageSharp.fluid} alt="Test House" />;
        break;
      case 5:
        return <HeaderImage fluid={bg5.childImageSharp.fluid} alt="ISO Certified" />;
        break;
      default:
        return <HeaderImage fluid={bg1.childImageSharp.fluid} alt="Statutory Inspections" />;
    }
  };

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [page]);

  const slides = [
    {
      order: 1,
      title: "30+ Years of Expertise in Statutory Inspections ",
      text:
        "We are Irelands Leading Statutory Inspection Company. Let us be Your Partners in Safety, contact us today to find out how.",
      buttonLink: "/about",
      buttonText: "Get In Touch",
    },
    {
      order: 2,
      title: "C.L.A.S.S. Ireland's best digital auditing system",
      text:
        "C.L.A.S.S. is the future of full compliance, see how our award-winning cloud based system can keep you fully compliant by contacting us for a free demo.",
      buttonLink: "/about",
      buttonText: "Request A Demo",
    },
    {
      order: 3,
      title: "Trusted throughout Ireland, here is why.",
      text:
        "Come and take a look through our previous work. We have seen and inspected it all, we have the stories and awards to prove it.",
      buttonLink: "/news",
      buttonText: "Case Studies",
    },
    {
      order: 4,
      title: "The LHS Services renowned Test House",
      text:
        "If your machinery or equipment needs unique testing, LHS offers bespoke on-site and in-house Statutory Inspections. Get a quote for your specialised test today.",
      buttonLink: "/about",
      buttonText: "Get A Quote",
    },
    {
      order: 5,
      title: "ISO Certified: We practice what we preach",
      text:
        "C.L.A.S.S. is an ISO Certified product. We pride ourselves on being experts in safety and passing on our know-how. Visit our Downloads page to get up to speed in Statutory Inspections.",
      buttonLink: "/downloads",
      buttonText: "LHS Resources",
    },
  ];

  const index = wrap(0, slides.length, page);

  return (
    <FadeIn>
      <Header>
        {getBackground(slides[index].order)}
        {slides[index].order === 1 && (
          <HeaderVideo autoPlay muted id="bgvid" poster={LHSVideoBanner} loop>
            <source src={LHSVideoMP4} type="video/mp4" />
            <source src={LHSVideoWebm} type="video/webm" />
          </HeaderVideo>
        )}
        <AnimatedBannerCircle order={slides[index].order} />
        <AnimatePresence initial={false} custom={direction}>
          <Carousel>
            <Container>
              <Slide
                key={page}
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 0 }}
                custom={direction}
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 200 },
                  opacity: { duration: 0.75 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                <Content order={slides[index].order}>
                  <Title>LHS Services</Title>
                  <Heading dangerouslySetInnerHTML={{ __html: slides[index].title }} />
                  <p dangerouslySetInnerHTML={{ __html: slides[index].text }} />
                  <Button to={slides[index].buttonLink}>{slides[index].buttonText}</Button>
                  <MobileDots style={{ paddingLeft: 0 }}>
                    <Paginate active={slides[index].order === 1} onClick={() => setPage([0, 0])} />
                    <Paginate active={slides[index].order === 2} onClick={() => setPage([1, 0])} />
                    <Paginate active={slides[index].order === 3} onClick={() => setPage([2, 0])} />
                    <Paginate active={slides[index].order === 4} onClick={() => setPage([3, 0])} />
                    <Paginate active={slides[index].order === 5} onClick={() => setPage([4, 0])} />
                  </MobileDots>
                </Content>
              </Slide>
            </Container>
          </Carousel>
        </AnimatePresence>
        <DesktopDots>
          <Paginate active={slides[index].order === 1} onClick={() => setPage([0, 0])} />
          <Paginate active={slides[index].order === 2} onClick={() => setPage([1, 0])} />
          <Paginate active={slides[index].order === 3} onClick={() => setPage([2, 0])} />
          <Paginate active={slides[index].order === 4} onClick={() => setPage([3, 0])} />
          <Paginate active={slides[index].order === 5} onClick={() => setPage([4, 0])} />
        </DesktopDots>
        <Badge>
          <h4>30+</h4>
          <p>Years in Inspections</p>
        </Badge>
      </Header>
    </FadeIn>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

// Animations

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Badge = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -1rem;
  right: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: #f94c00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    font-size: 2rem;
    line-height: 1em;
    font-weight: 600;
    color: white;
    margin: 0;
    margin-top: -0.25em;
  }
  p {
    font-size: 1rem;
    line-height: 1em;
    font-weight: 600;
    color: white;
    text-align: center;
    margin: 0;
    margin-top: 0.25em;
  }
  @media (min-width: 1150px) {
    width: 11rem;
    height: 11rem;
    bottom: 2rem;
    right: 3rem;
    h4 {
      font-size: 3rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

const Dots = styled.div`
  position: relative;
  z-index: 2;
  grid-template-columns: repeat(5, 1.5rem);
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  @media (min-width: 1150px) {
    padding-left: 5rem;
    padding-bottom: 5rem;
  }
`;

const DesktopDots = styled(Dots)`
  display: none;
  @media (min-width: 1150px) {
    display: grid;
  }
`;

const MobileDots = styled(Dots)`
  display: grid;
  @media (min-width: 1150px) {
    display: none;
  }
`;

const Paginate = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  width: 1.5rem;
  height: 0.5rem;
  border-radius: 2px;
  border: 1px solid white;
  border-color: ${({ active }) => (active ? "var(--dark-blue)" : "white")};
  background: ${({ active }) => (active ? "var(--dark-blue)" : "transparent")};
`;

const Button = styled(Link)`
  background: var(--light-blue);
  height: 3rem;
  width: auto;
  min-width: 150px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  border-radius: 0.425rem;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-weight: 300;
  line-height: 1.25em;
  font-size: 2.5rem;
  color: white;
  margin: 0.5em 0;
  @media (min-width: 1150px) {
    font-size: 3.5rem;
  }
`;

const Title = styled.h2`
  color: white;
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 600;
  color: #eaf6ff;
  margin-bottom: 0;
  @media (min-width: 1150px) {
    margin-bottom: 1rem;
  }
`;

const AnimatedBannerCircle = styled(BannerCircle)`
  height: calc(100% - 2.5rem);
  top: -5rem;
  left: 50%;
  transform-origin: center;
  transform: ${({ order }) => `rotate(${72 * order}deg)}`};
  z-index: 1;
  position: absolute;
  transition: 0.5s;
  @media (min-width: 768px) {
    width: 55rem;
    height: 55rem;
    top: -12.5rem;
  }
  @media (min-width: 1024px) {
    transform: ${({ order }) => `rotate(${72 * order}deg)}`};
    width: 60rem;
    height: 60rem;
    top: -15rem;
    left: -15rem;
  }
  @media (min-width: 1150px) {
    width: 75rem;
    height: 75rem;
    top: -15rem;
    left: -25rem;
    transform: ${({ order }) => `rotate(${72 * order}deg)}`};
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    position: relative;
    z-index: 2;
    color: white;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 600;
    margin-bottom: 3rem;
  }
  @media (min-width: 1024px) {
    width: 80%;
  }
  @media (min-width: 1150px) {
    width: 50%;
    padding-left: 5rem;
    padding-top: 7.5rem;
    padding-bottom: 2rem;

    p {
      font-size: 1.25rem;
      line-height: 1.3em;
      font-weight: 600;
      margin-bottom: 3rem;
      max-width: 90%;
    }
    h1 {
      max-width: 90%;
    }
  }
`;

const HeaderVideo = styled.video`
  width: 150%;
  height: auto;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  object-position: center;
  @media (min-width: 1150px) {
    height: 100%;
    top: 0;
    bottom: initial;
    right: -30rem;
  }
`;

const HeaderImage = styled(Img)`
  width: 100%;
  height: auto;
  position: absolute !important;
  z-index: 0;
  bottom: 0;
  right: 0;
  img {
    object-fit: cover;
    object-position: center !important;
  }
  @media (min-width: 768px) {
    height: 100%;
    top: 0;
    bottom: initial;
    img {
    object-fit: cover;
    object-position: 12.5rem 0rem !important;
  }
  }
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  color: #e79e6d;
  font-size: 16px;
  line-height: 24px;
  transform: translateY(0);
  grid-column: 1/7;
  position: relative;
  z-index: 2;
  @media (min-width: 768px) {
    grid-column: 1/5;
  }
  @media (min-width: 1150px) {
    font-size: 32px;
    line-height: 48px;
    grid-column: 1/13;
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(6, 1fr);
  @media (min-width: 768px) {
    padding: 2rem;
  }
  @media (min-width: 1024px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Carousel = styled.div`
  grid-area: carousel;
  width: 100%;
  height: 100%;
  grid-column: 1/7;
  @media (min-width: 1150px) {
    grid-column: 1/13;
  }
`;

const Header = styled.header`
  height: 52.5rem;
  width: 100%;
  background: white;
  position: relative;
  overflow: visible;
  margin-top: 3rem;
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    background-size: cover;
    height: 40rem;
    margin-bottom: 5rem;
  }
  @media (min-width: 1150px) {
    height: 55rem;
    margin-top: 0;
    margin-bottom: 5rem;
  }
  @media (min-width: 1440px) {
  }
`;
