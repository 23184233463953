import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useWindowSize } from "hooks";
import { Logo, Facebook, Linkedin, Twitter, EmailIcon, PhoneIcon } from "assets/svgs";
import { NavDropdown } from "components";
import { ContactInfo } from "components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default ({ navOpen, setNavOpen }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { windowWidth } = useWindowSize();

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  return (
    <Navbar navOpen={navOpen} scrolled={isScrolled}>
      <ContactInfo navOpen={navOpen} />
      <Container navOpen={navOpen}>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <NavToggler onClick={toggleNav} aria-label="Menu">
          <NavBurger navOpen={navOpen}>
            <BurgerBar navOpen={navOpen} />
            <BurgerBar navOpen={navOpen} />
            <BurgerBar navOpen={navOpen} />
          </NavBurger>
        </NavToggler>
      </Container>
      {(navOpen || windowWidth > 1150) && (
        <Links
          initial={windowWidth < 1150 ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.25 }}
        >
          <StyledLink to="/" onClick={closeNav}>
            Home
          </StyledLink>
          <NavDropdown />
          <StyledLink to="/class" onClick={closeNav}>
            C.L.A.S.S.
          </StyledLink>
          <StyledLink to="/news" onClick={closeNav}>
            News
          </StyledLink>
          <StyledLink to="/downloads" onClick={closeNav}>
            Downloads
          </StyledLink>
          <StyledLink to="/about" onClick={closeNav}>
            About Us
          </StyledLink>

          {windowWidth < 1150 && (
            <>
              <LinkButton className="contact" to="/about#contactform" stripHash onClick={closeNav}>
                Contact Us
              </LinkButton>
              <LinkButton to="https://classonline.lhsservices.ie/login">
                <Button onClick={closeNav}>CLASS Login</Button>
              </LinkButton>
              <FooterLinks>
                <Socials>
                  <Social
                    target="blank"
                    href="https://www.facebook.com/liftingandhandlingservices/"
                  >
                    <Facebook />
                  </Social>
                  <Social
                    target="blank"
                    href="https://www.linkedin.com/company/lifting-and-handling-services-ltd/about/"
                  >
                    <Linkedin />
                  </Social>
                </Socials>
                <Terms>
                  <Link to="/privacy" onClick={closeNav}>
                    Privacy Policy
                  </Link>
                </Terms>
              </FooterLinks>
            </>
          )}
        </Links>
      )}
      {windowWidth >= 1150 && (
        <TopRightLinks>
          <a className="external-link" href="https://classonline.lhsservices.ie/login">
            <Button onClick={closeNav}>CLASS Login</Button>
          </a>
          <LinkButton className="contact" to="/about#contactform" stripHash onClick={closeNav}>
            Contact Us
          </LinkButton>
        </TopRightLinks>
      )}
    </Navbar>
  );
};

const SvgContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background: #f94c00;
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CallUs = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #223f92;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  transition: 0.5s;
  > div {
    display: flex;
    align-items: center;
    color: white;
    &:first-child {
      margin-right: 1rem;
    }
    svg {
      height: 0.75rem;
      width: 0.75rem;
      max-height: 0.75rem;
    }
    a {
      font-size: 1rem;
      color: white;
      margin: 0;
      text-decoration: none;
      height: auto;
    }
  }
  @media (max-width: 1150px) {
    top: 5rem;
    display: ${({ navOpen }) => (navOpen ? "flex" : "none")};
    opacity: ${({ navOpen }) => (navOpen ? 1 : 0)};
    > div {
      a {
        font-size: 0.75rem;
      }
    }
  }
`;

const Terms = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  a {
    font-size: 0.75rem;
    color: white;
    text-decoration: none;
  }
`;

const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background: white;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    max-height: 1.25rem;
    max-width: 1.25rem;
    path {
      fill: #223f92;
    }
  }
`;

const Socials = styled.div`
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const FooterLinks = styled.div`
  width: 100%;
  height: auto;
  background: #124fdd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  margin-top: auto;
  margin-bottom: -4rem;
`;

const BurgerBar = styled.span`
  width: ${({ navOpen }) => (navOpen === true ? "1.25rem" : "1rem")};
  height: 0.185rem;
  background: white;
  position: absolute;
  display: block;
  content: "";
  transform-origin: center center;
  left: 50%;
  transition: 0.25s;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? "50%" : "0")};
    transform: ${({ navOpen }) =>
      navOpen === true ? "translate(-50%, -50%) rotate(45deg)" : "translateX(-50%)"};
  }
  &:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ navOpen }) => (navOpen === true ? "0" : "1")};
  }
  &:last-child {
    top: ${({ navOpen }) => (navOpen === true ? "50%" : "initial")};
    bottom: ${({ navOpen }) => (navOpen === true ? "initial" : "0")};
    transform: ${({ navOpen }) =>
      navOpen === true ? "translate(-50%, -50%) rotate(-45deg)" : "translateX(-50%)"};
  }
`;

const NavBurger = styled.div`
  width: ${({ navOpen }) => (navOpen === true ? "1.25rem" : "1rem")};
  height: 1rem;
  position: relative;
`;

const NavToggler = styled.button`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-self: end;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--secondary-color);
  border: none;
  outline: none;
  border-radius: 0.3rem;
  margin-right: 1em;
  @media (min-width: 1150px) {
    display: none;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0;
  svg {
    height: 2rem;
    width: auto;
    object-fit: cover;
    z-index: 2;
    margin-left: 1.25rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/4;
    svg {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  background: var(--secondary-color);
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  height: 3rem;
  width: 10rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  border: none;
  outline: none;
  color: white;
  border-radius: 0.425rem;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  transition: 0.25s;
  cursor: pointer;
  @media (min-width: 1150px) {
    grid-column: 11/13;
    margin: 0;
    font-weight: 300;
    font-size: 0.875rem;
    width: 7.5rem;
    height: 2rem;
    text-transform: capitalize;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
      transform: translateY(-1px);
    }
  }
`;

const LinkButton = styled(AnchorLink)`
  width: 100%;
  padding-top: 0.125rem;
  font-family: "Helvetica", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5em;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  color: #232528;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    background: #1188cc;
  }
  @media (min-width: 1150px) {
    width: auto;
    background: transparent;
    color: var(--black);
    font-size: 1rem;
    line-height: 1em;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.25s;
    padding-top: 3px;
    border-bottom: 3px solid transparent;
    &:hover {
      color: var(--secondary-color);
      border-bottom: 3px solid var(--secondary-color);
    }
  }
`;

const TopRightLinks = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  height: 100%;
  .external-link {
    width: 100%;
    height: 1000%;
    padding-top: 0.125rem;
    font-family: "Helvetica", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.5em;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-align: center;
    text-decoration: none;
    color: #232528;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 0.5rem;
    &.active {
      background: #1188cc;
    }
    @media (min-width: 1150px) {
      width: auto;
      background: transparent;
      color: var(--black);
      font-size: 1rem;
      line-height: 1em;
      padding: 0 1rem;
      height: 100%;
      display: flex;
      align-items: center;
      transition: 0.25s;
      padding-top: 3px;
      border-bottom: 3px solid transparent;
      &:hover {
        color: var(--secondary-color);
        border-bottom: 3px solid var(--secondary-color);
      }
    }
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  padding-top: 0.125rem;
  font-family: "Helvetica", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5em;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  color: #232528;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    background: #1188cc;
  }
  @media (min-width: 1150px) {
    width: auto;
    background: transparent;
    color: var(--black);
    font-size: 1rem;
    line-height: 1em;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.25s;
    padding-top: 3px;
    border-bottom: 3px solid transparent;
    &:hover {
      color: var(--secondary-color);
      border-bottom: 3px solid var(--secondary-color);
    }
  }
`;

const Links = styled(motion.div)`
  grid-area: links;
  grid-column: 1/7;
  height: auto;
  min-height: calc(100vh - 3.75rem);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 2rem;
  background: white;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding-top: 5rem;
  @media (min-width: 1150px) {
    min-height: auto;
    grid-column: 3/11;
    background: transparent;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
    height: 100%;
    margin-top: 0px;
    box-shadow: none;
    padding-top: 0;
    overflow: visible;
  }
`;

const Container = styled.div`
  height: 3.75rem;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  @media (min-width: 1150px) {
    grid-column: 1/3;
    box-shadow: none;
  }
`;

const Navbar = styled(motion.nav)`
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  height: auto;
  position: ${({ navOpen }) => (navOpen ? "sticky" : "fixed")};
  z-index: 5;
  top: 0;
  left: 0;
  transition: 0.25s;
  background: white;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-areas: ${({ navOpen }) => (navOpen === true ? `"logo" "links"` : `"logo"`)};
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  @media (min-width: 1150px) {
    position: fixed;
    left: 50%;
    transform: translateX(-50%) !important;
    height: 7rem;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "logo links";
    grid-gap: 1.25rem;
    padding: 0 2rem;
    padding-top: 1.5rem;
  }
`;
