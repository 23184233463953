import * as React from "react";

function SvgDarkBlueCircle(props) {
  return (
    <svg viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={400} cy={400} r={400} fill="#223F92" />
    </svg>
  );
}

export default SvgDarkBlueCircle;
