import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.87 9.066v8.117c0 .132.096.198.193.198h2.97c.13 0 .194-.1.194-.198V8.934h2.13c.097 0 .194-.099.194-.198l.194-2.475c0-.132-.097-.23-.194-.23H5.227V4.28c0-.428.323-.758.742-.758h1.647c.129 0 .194-.1.194-.198V.817c0-.099-.097-.198-.194-.198H4.839c-1.646 0-2.97 1.353-2.97 3.036v2.408H.384C.287 6.03.19 6.13.19 6.228v2.475c0 .132.097.198.194.198H1.87v.165z"
        fill="#223F92"
      />
    </svg>
  );
}

export default SvgFacebook;
