import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import styled from "styled-components";

function AuthorList({ items, title }) {
  return (
    <div>
      <Headline>{title}</Headline>
      <List>
        {items.map(({ author, _key }) => {
          const authorName = author && author.name;
          return (
            <ListItem key={_key}>
              <div>
                <Avatar>
                  {author && author.image && author.image.asset && (
                    <img
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(100)
                        .height(100)
                        .fit("crop")
                        .url()}
                      alt=""
                    />
                  )}
                </Avatar>
              </div>
              <div>
                <div>{authorName || <em>Missing name</em>}</div>
              </div>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

const Headline = styled.h2`
  font-weight: 600;
  margin: 0.5rem 0 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: var(--font-small-size);
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:last-child {
    flex: 1;
    margin-left: 0.5rem;
  }
`;

const Avatar = styled.div`
  position: relative;
  width: 3em;
  height: 3em;
  background: var(--color-very-light-gray);
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
`;

export default AuthorList;
