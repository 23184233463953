import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { mapEdgesToNodes } from "../../lib/helpers";
import { useWindowSize } from "hooks";
import { wrap } from "@popmotion/popcorn";
import { Arrow } from "assets/svgs";
import { FadeInUp } from "components";

export default () => {
  const { windowWidth } = useWindowSize();

  const data = useStaticQuery(graphql`
    query ClientsQuery {
      clients: allSanityClient {
        edges {
          node {
            id
            image {
              asset {
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const clients = (data || {}).clients ? mapEdgesToNodes(data.clients) : [];

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 5000);
    return () => clearTimeout(timer);
  }, [page]);

  const index = wrap(0, clients.length, page);
  const index2 = wrap(0, clients.length, page + 1);
  const index3 = wrap(0, clients.length, page + 2);
  const index4 = wrap(0, clients.length, page + 3);

  return (
    <Container>
      <FadeInUp>
        <ClientList
          key={page}
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
          custom={direction}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 200 },
            opacity: { duration: 0.75 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(3);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-3);
            }
          }}
        >
          <AnimatePresence initial={false} custom={direction}>
            {clients.length > 0 && (
              <>
                <Client id={clients[index].index} target="blank">
                  <Img fluid={clients[index].image.asset.fluid} alt={clients[index].name} />
                </Client>
                <Client id={clients[index2].index} target="blank">
                  <Img fluid={clients[index2].image.asset.fluid} alt={clients[index].name} />
                </Client>
                <Client id={clients[index3].index} target="blank">
                  <Img fluid={clients[index3].image.asset.fluid} alt={clients[index3].name} />
                </Client>
                {windowWidth >= 1150 && (
                  <Client id={clients[index4].index} target="blank">
                    <Img fluid={clients[index4].image.asset.fluid} alt={clients[index4].name} />
                  </Client>
                )}
              </>
            )}
          </AnimatePresence>
          {windowWidth >= 1150 && (
            <Left
              onClick={() => {
                paginate(-4);
              }}
            />
          )}
          {windowWidth < 1150 && (
            <Left
              onClick={() => {
                paginate(-3);
              }}
            />
          )}
          {windowWidth >= 1150 && (
            <Right
              onClick={() => {
                paginate(+4);
              }}
            />
          )}
          {windowWidth < 1150 && (
            <Right
              onClick={() => {
                paginate(+3);
              }}
            />
          )}
        </ClientList>
      </FadeInUp>
    </Container>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Dots = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: repeat(3, 1.5rem);
  grid-gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  @media (min-width: 1150px) {
    margin-top: 2rem;
  }
`;

const Paginate = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  width: 1.5em;
  height: 0.4rem;
  border-radius: 2px;
  border: 1px solid #35a8e0;
  border-color: ${({ active }) => (active ? "#223F92" : "#35A8E0")};
  background: ${({ active }) => (active ? "#223F92" : "transparent")};
`;

const Left = styled(Arrow)`
  cursor: pointer;
  padding: 0.75rem;
  min-width: 1rem;
  height: 2rem;
  transform: translateY(-50%) rotate(90deg);
  position: absolute;
  left: -1rem;
  top: 50%;

  @media (min-width: 768px) {
    left: 2rem;
  }
  @media (min-width: 1150px) {
    left: -1rem;
    margin-left: 2rem;
  }
`;

const Right = styled(Arrow)`
  cursor: pointer;
  padding: 0.75rem;
  min-width: 1rem;
  height: 2rem;
  transform: translateY(-50%) rotate(-90deg);
  position: absolute;
  right: -1rem;
  top: 50%;
  @media (min-width: 768px) {
    right: 2rem;
  }
  @media (min-width: 1150px) {
    right: -1rem;
    margin-right: 2rem;
  }
`;

const Client = styled(motion.div)`
  text-decoration: none;
  width: 100%;
  height: 3rem;
  max-height: 5rem;
  max-width: 12.5rem;
  margin: 0 1rem;
  &:hover {
    color: #f94c00;
  }
  .gatsby-image-wrapper {
    position: relative !important;
    height: 100%;
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    z-index: -1;
  }
`;

const ClientList = styled(motion.div)`
  width: auto;
  max-width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  padding: 0.5rem;
  position: relative;
  @media (min-width: 768px) {
    padding: 0.5rem 4rem;
  }
  @media (min-width: 1150px) {
    padding: 0.5rem;
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
`;
