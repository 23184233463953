import * as React from "react";

function SvgFooterCircle(props) {
  return (
    <svg viewBox="0 0 1423 1422" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx={711.5} cy={711} rx={711.5} ry={711} fill="#124FDD" />
    </svg>
  );
}

export default SvgFooterCircle;
