import * as React from "react";

function SvgComplianceIcon(props) {
  return (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.938 44.294h-6.563c-2.625 0-4.688 2.07-4.688 4.706h15.938c0-2.635-2.063-4.706-4.688-4.706zM25.188 10.412v33.882M25.187 6.647A2.818 2.818 0 0028 3.824C28 2.264 26.74 1 25.187 1a2.818 2.818 0 00-2.813 2.824c0 1.559 1.26 2.823 2.813 2.823zM25.375 14.835c6.375 0 8.624-7.247 16.124 0-7.5-2.54-10.687 4.894-16.124 4.894"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.593 42.412c3.844 0 6.938-2.918 7.406-6.588H34.188c.469 3.67 3.563 6.588 7.406 6.588z"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.688 33l5.906-17.882 6 17.882M24.625 14.835c-6.375 0-8.625-7.247-16.125 0 7.5-2.54 10.687 4.894 16.125 4.894"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.406 42.412c-3.844 0-6.937-2.918-7.406-6.588h14.906c-.469 3.67-3.656 6.588-7.5 6.588z"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.406 33l-6-17.882L2.5 33"
        stroke="#223F92"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComplianceIcon;
