import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../../lib/helpers";
import { FadeInUp } from "components";
import { useWindowSize } from "hooks";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default () => {
  const { windowWidth } = useWindowSize();

  const data = useStaticQuery(graphql`
    query ServicesQuery {
      services: allSanityService(limit: 10, sort: { order: ASC, fields: publishedAt }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            serviceSvg
          }
        }
      }
    }
  `);

  const services = (data || {}).services
    ? mapEdgesToNodes(data.services).filter(filterOutDocsWithoutSlugs)
    : [];
  return (
    <FadeInUp>
      <Services>
        {services.map((node) => (
          <Service key={node.id} to={`/services#${node.slug.current}`} title={node.title} stripHash>
            <SvgContainer dangerouslySetInnerHTML={{ __html: node.serviceSvg }} />
            <p>{node.title}</p>
          </Service>
        ))}
      </Services>
    </FadeInUp>
  );
};

const SvgContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
    max-height: 3em;
    margin-bottom: 1em;
  }
  @media (max-width: 400px) {
    svg {
      max-height: 2rem;
      margin-bottom: 0.5em;
    }
  }
  @media (min-width: 1150px) {
    svg {
      max-height: 5rem;
    }
  }
`;

const Service = styled(AnchorLink)`
  width: 184px;
  height: 184px;
  position: relative;
  transition: 0.3s;
  margin: 0.5em;
  border-radius: 0.5em;
  background: #eaf6ff;
  padding: 0.25em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.25s;
  p {
    margin: 0 auto;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.25em;
    color: #223f92;
    transition: 0.25s;
  }
  &:hover {
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.25);
    p {
      color: #f94c00;
    }
  }
  @media (max-width: 400px) {
    width: 100px;
    height: 100px;
    margin: 0.5em 0.25em;
    p {
      font-size: 0.625rem;
    }
  }
  @media (min-width: 1150px) {
    width: 225px;
    height: 225px;
    padding: 0.75rem;
    p {
      font-size: 1rem;
      line-height: 1.5em;
    }
  }
`;

const Services = styled.section`
  max-width: 100%;
  margin: 2rem auto;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0.5rem;
  background: #124fdd;
  border-radius: 10px;
  a {
    text-decoration: none;
  }
  @media (max-width: 400px) {
    padding: 0.5em;
  }
`;
