import * as React from "react";

function SvgCheckmark(props) {
  return (
    <svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8 8.1C11.1 10.4 9 12 6.5 12 3.5 12 1 9.5 1 6.5S3.5 1 6.5 1c1.2 0 2.3.4 3.2 1"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M3.9 6l3 2.9 6.5-6.5"
        stroke="#F94C00"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckmark;
